<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5></h5>
            <div class="ibox-tools">
              <router-link to="addEndpoint">
                <i class="fa fa-plus"></i> &nbsp;&nbsp; Add
              </router-link>
            </div>
          </div>
          <div class="ibox-content">
            <div class="table-responsive">
              <table class="table table-bordered table-hover" :class="dataTablesEndpoint">
                <thead>
                  <tr>
                    <th>{{ endpointColumn.id }}</th>
                    <th>{{ endpointColumn.name }}</th>
                    <th>{{ endpointColumn.url }}</th>
                    <th>{{ endpointColumn.configKey }}</th>
                    <th>{{ endpointColumn.httpTimeout }}</th>
                    <th>{{ endpointColumn.responseTimeout }}</th>
                    <th>{{ endpointColumn.isVersion }}</th>
                    <th>{{ endpointColumn.isApiProvider }}</th>
                    <th>{{ endpointColumn.isCustomer }}</th>
                    <th>{{ endpointColumn.action }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="gradeX" v-for="(value, key) in endpointData" :key="key">
                    <td style="width: 10%">{{ value.id }}</td>
                    <td style="width: 12%">{{ value.name }}</td>
                    <td style="width: 15%">{{ value.url }}</td>
                    <td style="width: 12%">{{ value.configKey }}</td>
                    <td style="width: 12%">{{ value.httpTimeout }}</td>
                    <td style="width: 12%">{{ value.responseTimeout }}</td>
                    <td style="width: 12%">{{ value.urlVersion }}</td>
                    <td style="width: 12%">{{ value.apiProvider }}</td>
                    <td style="width: 12%">{{ value.customerUrl }}</td>
                    <td style="width: 2%">
                      <i class="fas fa-user-cog"></i>
                      <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                        <i class="fa fa-wrench"></i>
                      </a>
                      <ul class="dropdown-menu dropdown-user">
                        <li>
                          <router-link
                            :to="{
                              path: '/updateEndpoint',
                              query: { id: value.id },
                            }"
                            class="dropdown-item"
                            >Edit</router-link
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            data-toggle="modal"
                            data-target="#myModal4"
                            @click="addModalData(value)"
                            >Delete</a
                          >
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>{{ endpointColumn.id }}</th>
                    <th>{{ endpointColumn.name }}</th>
                    <th>{{ endpointColumn.url }}</th>
                    <th>{{ endpointColumn.configKey }}</th>
                    <th>{{ endpointColumn.httpTimeout }}</th>
                    <th>{{ endpointColumn.responseTimeout }}</th>
                    <th>{{ endpointColumn.isVersion }}</th>
                    <th>{{ endpointColumn.isApiProvider }}</th>
                    <th>{{ endpointColumn.isCustomer }}</th>
                    <th>{{ endpointColumn.action }}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <DeleteModal :data="data"></DeleteModal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import DataTableService from "../plugin/DataTableService";
import DeleteModal from "../components/common/DeleteModal";
import EventBus from "../plugin/event-bus";
import AuditListner from "../plugin/AuditListner";

export default {
  data() {
    return {
      endpointColumn: {
        id: "Id",
        name: "Name",
        url: "URL",
        configKey: "Config Key",
        httpTimeout: "Http Timeout",
        responseTimeout: "Response Timeout",
        isVersion: "Version Remove",
        isApiProvider: "API Provider Remove",
        isCustomer: "Customer Remove",
        action: "Action",
      },
      endpointData: [],
      dataTablesEndpoint: "dataTables-Endpoint",
      exportTitle: "API Endpoint",
      data: {
        title: "API Endpoint",
        name: "",
        id: 0,
        url: Constant.url.ENDPOINT_DELETE,
      },
      selectedApiEndpointData: {},
    };
  },
  components: {
    DeleteModal,
  },
  created: function () {
    this.fetchendpointData();
    this.deleteEvent();
  },
  mounted: function () {
    
  },
  methods: {
    fetchendpointData: function () {
      var self = this;
      var responseCode = Constant.data.RESPONSE_CODE;
      AxiosService.get(Constant.url.ENDPOINT_ALL).then(async (result) => {
        self.endpointData = result;
        await DataTableService.loadDataTable(this.dataTablesEndpoint, this.exportTitle);
      });
    },
    addModalData: function (value) {
      this.selectedApiEndpointData = value;
      this.data.name = value.name;
      this.data.id = value.id;
    },
    deleteEvent: function () {
      const self = this;
      EventBus.$on(Constant.data.DELETE_EVENT, (data) => {
        if (data.responseCode == Constant.data.SUCCESS) {
          AuditListner.addAuditLog(
            AuditListner.initalizedData(
              Constant.data.API_ENDPOINT_ACTIVITY,
              Constant.data.DELETE_TITLE,
              AuditListner.apiEndpointAuditTemplate(self.selectedApiEndpointData)
            )
          );
          self.fetchendpointData();
          DataTableService.reLoadDataTable(self.dataTablesEndpoint);
          DataTableService.loadDataTable(self.dataTablesEndpoint, self.exportTitle);
        }
      });
    },
  },
  beforeDestroy() {
    EventBus.$off(Constant.data.DELETE_EVENT);
  },
};
</script>
<style scoped>
.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #0f0f0f !important;
}
a {
  color: #636363;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
</style>
